<!-- 选择取票页 -->
<template>
  <div class="wrapper tickes-list hei">
    <!-- 返回菜单 -->
    <back></back>
    <!-- 主体内容 -->
    <div class="container slide flex-grow">
      <ul>
        <li
          class="card flex-start relative"
          v-for="(item, index) in ticketList"
          :key="index"
        >
          <img class="ticket-img mr-3x" :src="item.skuImages" />
          <div class="card-text-wrap">
            <p class="font-xl text-1">{{ item.productName }}</p>
            <p class="font-l gray">票号：{{ item.auxiliaryId }}</p>
            <p class="font-l gray">
              使用有效期：{{ item.beginValidTime | date }} ~
              {{ item.endValidTime | date }}
            </p>
            <p class="font-l gray">预约日期：{{ item.createTime | date }}</p>
            <p class="font-l gray" v-if="item.appointmentPeriodName">
              预约时段：{{ item.appointmentPeriodName }}
            </p>
            <div class="check-wrap">
              <a-checkbox
                @change="onChange(item)"
                :defaultChecked="item.checked"
              >
                取该张票
              </a-checkbox>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 底部按钮 -->
    <div class="footer back-footer flex-between container">
      <p class="font-l">已选 {{ selected }} 张</p>
      <a-button type="primary" class="btn" @click="sure">取票</a-button>
    </div>

    <!-- 打印门票弹窗 -->
    <PrintTicket v-model="showDialog"></PrintTicket>
  </div>
</template>
<script>
import Back from "@/components/common/back";
import PrintTicket from "@/components/dialog/print-ticket";
import { orderSelfTicketprintinfo } from "../../api/search";
import { ticketPrint } from "../../api/order";
import { updatePaper } from "../../utils/global";

export default {
  name: "TickesList",
  components: {
    Back,
    PrintTicket
  },
  props: {},
  data() {
    return {
      showDialog: false,
      ticketList: [],
      selected: 0 // 已选的门票数
    };
  },
  mounted() {
    this.ticketList = this.$store.state.home.ticketCollection || [];
    this.selected = this.ticketList.length;
    this.ticketList.forEach(item => {
      item.checked = true;
    });
    this.$speak("请选择需要打印的门票");
  },
  methods: {
    sure() {
      if (this.selected < 1) {
        this.$speak("还未选择要取的门票");
        this.$message.warning("还未选择要取的门票");
        return;
      }
      // 筛选出要取的票
      const arr = this.ticketList.filter(ele => ele.checked);
      // 取票:都不需要激活、人脸采集、身份证录入
      let isDirectPrint = true;
      //2022-5-19注释需要身份证取票，走直接取票
      // arr.forEach(item => {
      //   if (item.byFaces || item.byTicketId || item.isNeedActivate != 0) {
      //     isDirectPrint = false;
      //   }
      // });
      // 直接取票、打印门票
      if (isDirectPrint) {
        this.printTicket(arr);
        // this.getTicket(arr);
      } else {
        this.$store.dispatch("home/setTouristInfoAction", arr);
        // 跳转登记游客信息页面：需要激活/录入人脸等
        this.$router.push({ name: "records" });
      }
      // 直接打印门票
      // this.showDialog = true;
    },
    // 多选框
    onChange(item) {
      item.checked ? (item.checked = false) : (item.checked = true);
      const arr = this.ticketList.filter(ele => ele.checked);
      this.selected = arr.length;
    },
    // 取票
    getTicket(arr) {
      const list = [];
      arr.forEach(item => {
        list.push({
          ifActivateNow: false, // 是否立即激活
          id: item.id, // 门票订单详情记录id
          auxiliaryId: "", // 主票号或子票号
          idCardNo: "", // 票号绑定的身份证号
          faceId: "", // 票号绑定的人脸id
          ticketPrintSubAuxiliaryInfoList: [] // 子票绑定的身份证人脸信息
        });
      });
      ticketPrint({
        channel: "", // 打印渠道,非必填
        auxiliaryInfoList: list
      })
        .then(() => {
          // this.printTicket(arr);
        })
        .catch(() => {});
    },
    // 打印门票
    printTicket(arr) {
      const ids = [];
      arr.forEach(item => {
        ids.push(item.id);
      });
      // 系统初始化时，是否设置了门票打印模板，项目套餐打印模板
      const Hardware = localStorage.getItem("Hardware");
      let ticketTempId = "";
      if (Hardware) {
        ticketTempId = JSON.parse(Hardware).machinePrintTemplate;
      }
      orderSelfTicketprintinfo({
        orderDetailIds: ids.join(","),
        templateId: ticketTempId
      })
        .then(res => {
          if (res) {
            try {
              const bool = window.test.printTicket(JSON.stringify(res));
              if (!bool) {
                this.$speak("打印失败，请联系管理员查看打印机是否连接");
                this.$warning("打印失败，请联系管理员查看打印机是否连接");
                return;
              }
              this.$speak("打印门票中，请勿离开");
              this.showDialog = true;
              // 更新纸张数
              updatePaper(1, res.length, this);
              this.getTicket(arr); // 打印完成，调取票状态变更接口
            } catch (error) {
              this.showDialog = false;
              this.$speak("门票打印失败，请联系管理员");
              console.log(error, "打印失败方法，来到catch");
            }
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.ticket-img {
  width: 120px;
  height: 120px;
  border-radius: @2x;
}
.card-text-wrap {
  max-width: 550px;
  p {
    line-height: 30px;
  }
  .check-wrap {
    position: absolute;
    right: @3x;
    bottom: @2x;
  }
}
/deep/.ant-checkbox-inner {
  width: 30px;
  height: 30px;
  &::after {
    width: 8px;
    height: 18px;
  }
}
.ant-checkbox-wrapper {
  font-size: 24px;
}
</style>
