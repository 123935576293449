<!-- 取票登记页 -->
<template>
  <div class="wrapper hei">
    <!-- 返回菜单 -->
    <back text="登记顾客信息"></back>
    <div class="container flex-grow">
      <div class="card-lg">
        <!-- 登记游客信息表单 -->
        <a-form-model
          v-if="update"
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label-align="left"
        >
          <a-form-model-item label="门票名称">
            <span>{{ clientData[curPage - 1].productName }}</span>
          </a-form-model-item>
          <a-form-model-item label="票号">
            <span>{{ clientData[curPage - 1].auxiliaryId }}</span>
          </a-form-model-item>
          <a-form-model-item label="姓名">
            <span>{{ clientData[curPage - 1].playPersonName || "-" }}</span>
          </a-form-model-item>
          <a-form-model-item
            label="门票激活"
            v-if="clientData[curPage - 1].isNeedActivate == 1"
          >
            <a-checkbox :defaultChecked="clientData[curPage - 1].active"
              >立即激活</a-checkbox
            >
          </a-form-model-item>
          <!-- 2022-5-19，隐藏取票时身份证录入-保留代码-后期可能启用 -->
          <!-- <a-form-model-item
            label="身份证号码"
            v-if="clientData[curPage - 1].byTicketId && update"
          >
            <template v-for="(item, index) in clientData"> -->
          <!-- 九空格输入组件 -->
          <!-- <keyboard
                :key="index"
                :defaultInput="false"
                v-show="curPage - 1 == index"
                :open-idcard="true"
                @ok="ok"
                @dele="dele"
                @keydown="keydown"
              >
                <a-input
                  slot="input"
                  readOnly
                  @change="inputChange"
                  allow-clear
                  v-model="clientData[curPage - 1].idCardNum"
                  placeholder="请输入身份证号码"
                />
              </keyboard> -->
          <!-- 原输入组件 -->
          <!-- <IdCardInput
                :key="index"
                @readCard="readCard"
                v-show="curPage - 1 == index"
                v-model="clientData[curPage - 1].idCardNum"
              ></IdCardInput> -->
          <!-- </template>
          </a-form-model-item> -->
          <a-form-model-item
            label="人脸信息"
            v-if="clientData[curPage - 1].byFaces"
          >
            <template v-for="(item, index) in clientData">
              <FaceInput
                :key="index"
                v-show="curPage - 1 == index"
                v-model="clientData[curPage - 1].faceId"
              ></FaceInput>
            </template>
          </a-form-model-item>
        </a-form-model>
        <!-- 分页 -->
        <div
          class="flex-between font-xl"
          v-if="clientData && clientData.length > 1"
        >
          <p @click="pagePre" v-if="curPage > 1">
            <a-icon type="left-circle" theme="filled" />
            上一张
          </p>
          <span class="font-m">
            第 {{ curPage }} 张（ 共 {{ clientData.length }} 张）
          </span>
          <p @click="pageNext" v-if="curPage != clientData.length">
            下一张
            <a-icon type="right-circle" theme="filled" />
          </p>
        </div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="footer back-footer flex-between container">
      <p class="font-l">
        合计 {{ clientData.length }} 张
        <!-- <span>已登记 {{ registered }} 张</span> -->
      </p>
      <a-button type="primary" class="btn" @click="sure">确定取票</a-button>
    </div>

    <!-- 打印门票弹窗 -->
    <PrintTicket v-model="showDialog"></PrintTicket>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import Back from "@/components/common/back";
// import Keyboard from "@/components/common/keyboard";
// import IdCardInput from "@/components/hardware/id-card-input";
import FaceInput from "@/components/hardware/face-input";
import PrintTicket from "@/components/dialog/print-ticket";
import { ticketPrint } from "../../api/order";
import { orderSelfTicketprintinfo } from "../../api/search";
// import { updatePaper, isIdcard } from "../../utils/global";
import { updatePaper } from "../../utils/global";

export default {
  name: "Records",
  components: {
    // Keyboard,
    Back,
    // IdCardInput,
    FaceInput,
    PrintTicket
  },
  props: {},
  data() {
    return {
      update: true,
      form: {
        // activation: true
      },
      rules: {},
      curPage: 1,
      clientData: [],
      showDialog: false,
      registered: 0 // 已经登记的票数
    };
  },
  created() {
    const arr = this.$store.state.home.touristInfo || [];
    arr.forEach(item => {
      item.active = true; // 激活
      item.faceId = ""; // 人脸采集
      item.idCardNum = ""; // 身份证
    });
    this.clientData = arr;
  },
  mounted() {
    this.setPage(this.curPage);
  },
  methods: {
    ...mapMutations("order", ["setPage"]),
    //强渲染
    updates() {
      this.update = false;
      this.$nextTick(() => {
        this.update = true;
      });
    },
    //删除
    dele() {
      let str = this.clientData[this.curPage - 1].idCardNum;
      this.clientData[this.curPage - 1].idCardNum = str.substr(
        0,
        str.length - 1
      );
      this.updates();
    },
    //键盘输入
    keydown(num) {
      this.clientData[this.curPage - 1].idCardNum += num;
      this.updates();
    },
    //输入change
    inputChange(e) {
      if (!e.target.value) {
        this.$speak("清空");
        this.updates();
      }
    },
    // 键盘确定
    ok(id, bool = true) {
      if (bool) {
        this.clientData[this.curPage - 1].idCardNum = id;
        this.updates();
      }
      if (!this.clientData[this.curPage - 1].idCardNum) {
        this.$speak("请先输入号码");
        return;
      }
    },
    // 分页点击事件
    pagePre() {
      if (this.curPage == 1) {
        this.$message.warning("已经是第一页了");
        return;
      }
      this.curPage = this.curPage - 1;
    },
    //下一张
    pageNext() {
      if (this.curPage == this.clientData.length) {
        this.$message.warning("已经是最后一页了");
        return;
      }
      const curObj = this.clientData[this.curPage - 1];
      // if (curObj.byTicketId && !curObj.idCardNum) {
      //   this.$message.warning("请先录入身份证号码");
      //   return;
      // }
      if (curObj.byFaces && !curObj.faceId) {
        this.$message.warning("请先采集人脸信息");
        return;
      }
      this.curPage = this.curPage + 1;
      this.registered += 1;
      this.setPage(this.curPage);
    },
    //身份证读取
    readCard(info) {
      this.clientData[this.curPage - 1].idCardNum = info.id;
      this.updates();
    },
    //确定取票
    sure() {
      const len = this.clientData && this.clientData.length;
      let msg = "";
      for (let i = 0; i < len; i++) {
        let productName = this.clientData[i].productName; //门票名称
        // 需要填写身份证
        // if (this.clientData[i].byTicketId) {
        //   //未录入
        //   if (!this.clientData[i].idCardNum) {
        //     msg = `第${i + 1}张门票：【${productName}】，还没录入身份证`;
        //     break;
        //   } else {
        //     //校验录入的身份证格式
        //     const val = isIdcard(this.clientData[i].idCardNum);
        //     if (val) {
        //       msg = `第${i + 1}张门票：【${productName}】，${val}`;
        //       break;
        //     }
        //   }
        //   // 校验身份证号码是否与出游人一致
        //   if (
        //     this.clientData[i].idCardNum !== this.clientData[i].playPersonIdCard
        //   ) {
        //     msg = `第${i +
        //       1}张门票：【${productName}】，身份证号码错误，请重新输入！`;
        //     break;
        //   }
        // }
        // 需要人脸采集，但未录入
        if (this.clientData[i].byFaces && !this.clientData[i].faceId) {
          msg = `第${i + 1}张门票：【${productName}】，需要人脸采集`;
          break;
        }
      }
      if (msg) {
        this.$warning(msg);
        return;
      }
      // this.getTicket();
      this.printTicket();
    },
    // 取票状态变更接口
    getTicket() {
      const list = [];
      this.clientData.forEach(item => {
        list.push({
          ifActivateNow: item.active, // 是否立即激活
          id: item.id, // 门票订单详情记录id
          auxiliaryId: "", // 主票号或子票号
          idCardNo: item.idCardNum, // 票号绑定的身份证号
          faceId: item.faceId, // 票号绑定的人脸id
          ticketPrintSubAuxiliaryInfoList: [] // 子票绑定的身份证人脸信息
        });
      });
      ticketPrint({
        channel: "", // 打印渠道,非必填
        auxiliaryInfoList: list
      })
        .then(() => {
          // this.printTicket();
        })
        .catch(() => {});
    },
    // 打印门票
    printTicket() {
      const ids = [];
      this.clientData.forEach(item => {
        ids.push(item.id);
      });
      // 系统初始化时，是否设置了门票打印模板，项目套餐打印模板
      const Hardware = localStorage.getItem("Hardware");
      let ticketTempId = "";
      if (Hardware) {
        ticketTempId = JSON.parse(Hardware).machinePrintTemplate;
      }
      orderSelfTicketprintinfo({
        orderDetailIds: ids.join(","),
        templateId: ticketTempId
      })
        .then(res => {
          if (res) {
            try {
              const bool = window.test.printTicket(JSON.stringify(res));
              if (!bool) {
                this.$speak("打印失败，请联系管理员查看打印机是否连接");
                this.$warning("打印失败，请联系管理员查看打印机是否连接");
                return;
              }
              this.$speak("打印门票中，请勿离开");
              this.showDialog = true;
              // 更新纸张数
              updatePaper(1, res.length, this);
              this.getTicket(); // 打印完成，调取票状态变更接口
            } catch (error) {
              this.showDialog = false;
              this.$speak("门票打印失败，请联系管理员");
              console.log(error, "打印失败方法，来到catch");
            }
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.ant-form-item {
  font-size: 26px;
  margin-bottom: 40px;
}
/deep/.ant-form label {
  font-size: 26px;
}
/deep/.ant-checkbox-inner {
  width: 30px;
  height: 30px;
  &::after {
    width: 8px;
    height: 18px;
  }
}
</style>
