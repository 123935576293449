<template>
  <div class="wrapper hei">
    <!-- 扫码提示语 -->
    <tips></tips>
    <!-- 键盘与版本号 -->
    <div class="res pv-1x bg-gray-dark keybord ">
      <keyboard
        :open-idcard="true"
        v-model="numbers"
        @ok="ok"
        @clear="clear"
      ></keyboard>
    </div>
    <!-- 版本号 -->
    <bottom-ota></bottom-ota>
    <!-- 打印票 弹窗 -->
    <print-ticket
      :need-back-home="false"
      v-if="showDialog"
      v-model="showDialog"
    ></print-ticket>
    <!-- 查询结果弹窗 -->
    <Result
      :vis.sync="resultDialog"
      :type="resultType"
      :text="resultText"
    ></Result>
  </div>
</template>
<script>
import Tips from "@/components/common/tips";
import Keyboard from "@/components/common/keyboard";
import BottomOta from "@/components/common/bottom-ota";
import PrintTicket from "@/components/dialog/print-ticket";
import Result from "../../components/dialog/result.vue";
import {
  orderSelfTakeTicket,
  orderSelfTicketprintinfo
} from "../../api/search";
import { ticketPrint } from "../../api/order";
import { updatePaper } from "../../utils/global";

export default {
  name: "Home",
  components: {
    Tips,
    Keyboard,
    BottomOta,
    PrintTicket,
    Result
  },
  props: {},
  data() {
    return {
      numbers: "",
      showDialog: false,
      resultDialog: false,
      resultType: 1,
      resultText: ""
    };
  },
  created() {
    this.$speak("请将IC卡放置在下方读卡器或将取票二维码对准右下角扫描器");
  },
  methods: {
    // 键盘确定
    ok() {
      if (!this.numbers) {
        this.$speak("请先输入号码");
        this.resultText = "请先输入号码";
        this.resultType = 1;
        this.resultDialog = true;
        return;
      }
      // 取票查询接口
      orderSelfTakeTicket({
        keyword: this.numbers // 订单号/取票号/票号/取票人手机号/取票人身份证
      })
        .then(res => {
          if (!res || res.length <= 0) {
            this.$speak("查询不到可取门票");
            this.resultText = "查询不到可取门票";
            this.resultType = 2;
            this.resultDialog = true;
            this.numbers = "";
            return;
          }
          // 只买了一份的门票（如：买了1张票；或买了一张1票3人的票等）
          if (res && res.length == 1) {
            // 取票不需输入身份证、不需要人脸采集、不需激活
            if (
              !res[0].byFaces &&
              !res[0].byTicketId &&
              res[0].isNeedActivate == 0
            ) {
              // this.getTicket(res[0]);
              // 先打印，再调取票状态变更接口
              this.printTicket(res[0]);
            } else {
              this.$store.dispatch("home/setTouristInfoAction", res);
              // 需要激活、或人脸采集、或输入身份证
              this.$router.push({ name: "records" });
            }
            return;
          }
          // 买了多份门票（如：一张票买了2张；或买了2张不同的票等）
          if (res && res.length > 1) {
            this.$store.dispatch("home/SetTicketCollection", res);
            this.$router.push({ name: "tickes-list" });
          }
        })
        .catch(() => {
          this.numbers = "";
        });
    },
    // 键盘清空
    clear() {
      this.numbers = "";
    },
    // 取票
    getTicket(data) {
      ticketPrint({
        channel: "", // 打印渠道,非必填
        auxiliaryInfoList: [
          {
            ifActivateNow: data.isNeedActivate == 1 ? true : false, // 是否立即激活
            id: data.id, // 门票订单详情记录id
            auxiliaryId: "", // 主票号或子票号
            idCardNo: "", // 票号绑定的身份证号
            faceId: "", // 票号绑定的人脸id
            ticketPrintSubAuxiliaryInfoList: [] // 子票绑定的身份证人脸信息
          }
        ]
      })
        .then(() => {
          // this.printTicket(data);
        })
        .catch(() => {});
    },
    // 打印门票
    printTicket(data) {
      // 系统初始化时，是否设置了门票打印模板，项目套餐打印模板
      const Hardware = localStorage.getItem("Hardware");
      let ticketTempId = "";
      if (Hardware) {
        ticketTempId = JSON.parse(Hardware).machinePrintTemplate;
      }
      orderSelfTicketprintinfo({
        orderDetailIds: data.id,
        templateId: ticketTempId
      })
        .then(res => {
          if (res) {
            try {
              const bool = window.test.printTicket(JSON.stringify(res));
              if (!bool) {
                this.$speak("打印失败，请联系管理员查看打印机是否连接");
                this.$warning("打印失败，请联系管理员查看打印机是否连接");
                return;
              }
              this.$speak("打印门票中，请勿离开");
              this.numbers = "";
              // 更新纸张数
              updatePaper(1, res.length, this);
              this.showDialog = true;
              this.getTicket(data); // 打印完成，调取票状态变更接口
            } catch (error) {
              this.showDialog = false;
              this.$speak("门票打印失败，请联系管理员");
              console.log(error, "打印失败方法，来到catch");
            }
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.keybord {
  padding-left: @1x * 10;
  padding-right: @1x * 10;
  box-sizing: border-box;
}
</style>
